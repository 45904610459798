import React from "react"
import styled from "styled-components"
import Link from "gatsby-link"
import Parser from "html-react-parser"
import Slide from "react-reveal/Slide"

import Container from "../elements/Container"
import Title, { TitleH2 } from "../elements/Title"
import ButtonPlus from "../elements/Button/ButtonPlus"
import globalVariables from "../globalVariables"

const Section = styled.section`
  position: relative;
  padding: 45px 0;
  background-color: ${props => props.theme.greyLighter};
  z-index: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 80px 0 105px;
    overflow: hidden;
  }
`

const WrapperThematiques = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > div {
      flex: 1 1 33.33%;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    & > div {
      flex: 0 0 24.9%;
    }
  }
`

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  text-decoration: none;
  background: white;
  margin: 12.5px;
  position: relative;
  background: ${props => props.theme.white};
  height: 250px;
  .title2 {
    margin-bottom: 10px;
    color: ${props => props.theme.bodyColor};
  }
  .title1 {
    font-size: 26px;
    margin: 0;
  }
`

const CardHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 16px 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Card}:hover & {
    opacity: 1;
  }
`

const CardText = styled.div`
  p {
    margin-bottom: 0;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    padding: 0;
    text-align: center;

    p {
      color: ${props => props.theme.bodyColor};
    }
  }
`

const WrapperPlus = styled.div`
  text-align: center;

  @media (max-width: ${globalVariables.maxMobile}) {
    & > button {
      width: 40px;
      height: 40px;
      min-width: auto;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 12px;
    & > button {
      width: 50px;
      height: 50px;
      min-width: auto;
    }
  }
`

const Thematiques = ({ title, formations }) => {
  return (
    <Section>
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>

        <WrapperThematiques>
          {formations.map((item, index) => (
            <Slide bottom key={index}>
              <Card to={item.node.path}>
                <Title className="title2" position="center">
                  {item.node.acf.titre_partie_banniere_1}
                </Title>
                <Title className="title1" position="center" color="primary">
                  {item.node.acf.titre_partie_banniere_2}
                </Title>
                <CardHover>
                  <CardText>{Parser(item.node.acf.texte_banniere)}</CardText>
                  <WrapperPlus>
                    <ButtonPlus />
                  </WrapperPlus>
                </CardHover>
              </Card>
            </Slide>
          ))}
        </WrapperThematiques>
      </Container>
    </Section>
  )
}

export default Thematiques
