import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FormationContent from "../components/FicheFormation/"
import Event from "../components/Contact/event"
import Contact from "../components/Home/Contact"
import BannerV2 from "../components/elements/BannersV2/BannerV2"
import Thematiques from "../components/Prestations/thematiques"
import metiersData from "../utils/metierData"

const anchors = [
  // {
  //   content: "Problématiques",
  //   anchor: "agence-bloc-1",
  // },
  {
    content: "Détail de la prestation",
    anchor: "detail-prestation",
  },
  // {
  //   content: "Notre accompagnement",
  //   anchor: "valeurs",
  // },
]

const Prestation = ({ data, location }) => {
  const {
    articles,
    prestation_seo,
    prestation_sea,
    prestation_sma,
    prestation_analytics,
    prestation_ux_cro,
    prestation_cro,
    prestation_data,
    evenement,
    bloc_contact,
  } = data

  const getData = () => {
    if (prestation_seo) {
      return prestation_seo
    } else if (prestation_sea) {
      return prestation_sea
    } else if (prestation_sma) {
      return prestation_sma
    } else if (prestation_analytics) {
      return prestation_analytics
    } else if (prestation_cro) {
      return prestation_cro
    } else if (prestation_data) {
      return prestation_data
    } else {
      return prestation_ux_cro
    }
  }
  const prestationData = getData()
  const metier = prestationData.acf.metier.toLowerCase()

  const getPrestations = () => {
    if (metier === metiersData.SOCIAL_ADS.toLowerCase()) {
      return data.sma
    } else if (metier === metiersData.WEB_ANALYTICS.toLowerCase()) {
      return data.analytics
    } else {
      return data[metier]
    }
  }
  const prestations = getPrestations()
  const bannerLeftCardContent = {
    mainContent: prestationData.acf.texte,
    titleFirstLine: prestationData.acf.titre_1,
    titleSecondLine: prestationData.acf.titre_2,
    subTitle: prestationData.acf.sous_titre,
  }

  return (
    <Layout
      location={`/${location.pathname.split("/")[1]}`}
      locationBis={`/${location.pathname.split("/")[1] +
        "/" +
        location.pathname.split("/")[2]}`}
      locationTer={location.pathname}
      crumbLabel="Nos Expertises"
      crumbLabelBis={prestationData.acf.metier}
      crumbLabelTer={bannerLeftCardContent.titleSecondLine}
      locationGTM={location.href}
      noLocation
    >
      <SEO
        title={prestationData.acf.title}
        description={prestationData.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasTag
        hasBreadCrumb
        hasTypeform
        hasSlider
        isPrestationPage
        bannerLeftCardContent={bannerLeftCardContent}
        articles={articles.edges
          .filter(item => metier === item.node.acf.theme_article.toLowerCase())
          .slice(0, 3)}
      />
      <FormationContent
        body={prestationData.acf.contenu}
        contact={prestationData.acf.texte_contact}
        locationBack={`/${location.pathname.split("/")[1] +
          "/" +
          location.pathname.split("/")[2]}`}
        ancreText={prestationData.acf.texte_ancre}
      />
      {evenement.acf.statut_evenement &&
        evenement.acf.statut_evenement === "Publié" && (
          <Event
            banner={
              evenement.acf.image_evenement.localFile.childImageSharp.fluid.src
            }
            titre={evenement.acf.titre_evenement}
            texte={evenement.acf.texte_evenement}
            date={evenement.acf.date_evenement}
            heure={evenement.acf.heure_evenement}
            adresse={evenement.acf.adresse_evenement}
            lieu={evenement.acf.lieu_evenement}
            lien={evenement.acf.lien_evenement}
          />
        )}
      <Thematiques
        title={prestationData.acf.titre_thematique}
        formations={prestations.edges}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
        id="contact-form"
      />
    </Layout>
  )
}

export default Prestation

export const query = graphql`
  query($id: String!, $theme: String!) {
    prestation_seo: wordpressWpSeo(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    prestation_sea: wordpressWpSea(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    prestation_sma: wordpressWpSma(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    prestation_analytics: wordpressWpAnalytics(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    prestation_ux_cro: wordpressWpUxCro(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    prestation_cro: wordpressWpCro(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    prestation_data: wordpressWpData(id: { eq: $id }) {
      path
      acf {
        title
        contenu
        couleur_mignature_prestation
        image
        meta_description
        metier
        metier_banniere
        texte_banniere
        texte_contact
        texte_ancre
        titre_partie_banniere_1
        titre_partie_banniere_2
        titre_thematique
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
      }
    }
    evenement: wordpressPage(
      id: { eq: "412e0a3c-3d39-57c8-b08f-9e8b26826d0a" }
    ) {
      acf {
        adresse_evenement
        date_evenement
        heure_evenement
        lien_evenement
        lieu_evenement
        statut_evenement
        titre_evenement
        texte_evenement
        image_evenement {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
    seo: allWordpressWpSeo {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    sea: allWordpressWpSea {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    sma: allWordpressWpSma {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    analytics: allWordpressWpAnalytics {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    ux: allWordpressWpUxCro {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    cro: allWordpressWpCro {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    data: allWordpressWpData {
      edges {
        node {
          path
          acf {
            metier
            titre_partie_banniere_1
            titre_partie_banniere_2
            texte_banniere
          }
        }
      }
    }
    articles: allWordpressPost(
      limit: 3
      filter: { acf: { theme_article: { eq: $theme } } }
      sort: { order: DESC, fields: modified }
    ) {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            titre_article
            intro_article
          }
        }
      }
    }
  }
`
